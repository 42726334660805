/* scrollbar */
@import '../node_modules/simplebar-react/dist/simplebar.min.css';

/* lazy image */
@import '../node_modules/react-lazy-load-image-component/src/effects/blur.css';

/* map */
@import '../node_modules/mapbox-gl/dist/mapbox-gl.css';

/* carousel */
@import '../node_modules/slick-carousel/slick/slick.css';
@import '../node_modules/slick-carousel/slick/slick-theme.css';

/* lightbox */
@import '../node_modules/yet-another-react-lightbox/dist/styles.css';
@import '../node_modules/yet-another-react-lightbox/dist/plugins/captions/captions.css';
@import '../node_modules/yet-another-react-lightbox/dist/plugins/thumbnails/thumbnails.css';

/* hidden scrollbar */
.hideScrollbar {
  -ms-overflow-style: none;
  /* scrollbar-width: none; */
  overflow-y: scroll;
}

.hideScrollbar::-webkit-scrollbar {
  display: none;
}

.historySearchInput::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.rdrStartEdge {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0!important;
   left: 0!important;
  right: 0!important;
}
.rdrEndEdge {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0!important;
  border-bottom-right-radius: 0!important;
   left: 0!important;
  right: 0!important;
}
.rdrInRange {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0!important;
  border-bottom-right-radius: 0!important;
  left: 0!important;
  right: 0!important;
}

.rdrDayStartOfMonth .rdrDayInPreview, .rdrDayStartOfMonth .rdrDayEndPreview, .rdrDayStartOfWeek .rdrDayInPreview, .rdrDayStartOfWeek .rdrDayEndPreview{
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0!important;
  border-bottom-right-radius: 0!important;
  left: 0!important;
  right: 0!important;
}

.rdrDayEndOfMonth .rdrDayInPreview, .rdrDayEndOfMonth .rdrDayStartPreview, .rdrDayEndOfWeek .rdrDayInPreview, .rdrDayEndOfWeek .rdrDayStartPreview{
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0!important;
  border-bottom-right-radius: 0!important;
  left: 0!important;
  right: 0!important;
}
.rdrStartEdge{
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0!important;
  border-bottom-right-radius: 0!important;
  left: 0!important;
  right: 0!important;
}

.rdrEndEdge{
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0!important;
  border-bottom-right-radius: 0!important;
  left: 0!important;
  right: 0!important;
}

.rdrSelected{
  border-radius: 0!important;
}

.rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge{
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0!important;
  border-bottom-right-radius: 0!important;
  left: 0!important;
  right: 0!important;
}

.rdrDayEndOfMonth .rdrInRange,  .rdrDayEndOfMonth .rdrStartEdge,  .rdrDayEndOfWeek .rdrInRange,  .rdrDayEndOfWeek .rdrStartEdge{
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0!important;
  border-bottom-right-radius: 0!important;
  left: 0!important;
  right: 0!important;
}

.rdrDayStartOfMonth .rdrDayInPreview, .rdrDayStartOfMonth .rdrDayEndPreview, .rdrDayStartOfWeek .rdrDayInPreview, .rdrDayStartOfWeek .rdrDayEndPreview{
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0!important;
  border-bottom-right-radius: 0!important;
  left: 0!important;
  right: 0!important;
}

.rdrDayEndOfMonth .rdrDayInPreview, .rdrDayEndOfMonth .rdrDayStartPreview, .rdrDayEndOfWeek .rdrDayInPreview, .rdrDayEndOfWeek .rdrDayStartPreview{
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0!important;
  border-bottom-right-radius: 0!important;
  left: 0!important;
  right: 0!important;
}
.rdrDayStartPreview{
  border-top-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0!important;
  border-bottom-right-radius: 0!important;
  left: 0!important;
  right: 0!important;
}

.rdrDayInPreview{
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.rdrDayEndPreview{
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-top-right-radius: 0!important;
  border-bottom-right-radius: 0!important;
  left: 0!important;
  right: 0!important;
}

.ql-align-right{
  text-align: right;
}
.ql-align-center{
  text-align: center;
}


